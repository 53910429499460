//import _, { initial } from "lodash";
import { mapState } from "vuex";
import { EmptyTargetControl } from "./../controls/emptyTargetControl.js";

import MapControlsUi from "./../controls/mapControls.vue";
import { eventBus } from "../../../main";

/*
      let genThematic = ({ maxValue, field, thematicColors }) => {
        let legendArray = [];

        let output = ["interpolate", ["linear"], ["get", field]];

        thematicColors.forEach((color, index, arr) => {
          let length = arr.length;

          let threshold = index * (1 / length) * maxValue;
          let thresholdNext = (index + 1) * (1 / length) * maxValue;

          output.push(threshold);
          output.push(color);

          legendArray.push({
            min: Math.round(threshold * 10) / 10,
            max: Math.round(thresholdNext * 10) / 10,
            color
          });
        });

        return { style: output, legendArray };
      };
*/

let genNewThematicLinear = ({ field, thematicColorSet }) => {
  let legendArray = [];

  let output = ["interpolate", ["linear"], ["get", field]];

  thematicColorSet.forEach((row, index, arr) => {
    let length = arr.length;
    let { min, max, color } = row;
    output.push(min);
    output.push(color);
    if (length - 1 == index) {
      output.push(max);
      output.push(color);
    }
    legendArray.push({
      min,
      max,
      color
    });
  });

  return { style: output, legendArray };
};

var cartoOutput = {};
let genNewThematicStep = ({ field, thematicColorSet, name }) => {
  let cartoCssPart = [];
  let legendList = [];

  let legendArray = [];

  let output = ["step", ["get", field]];

  let first = true;

  thematicColorSet.forEach((row, index, arr) => {
    let length = arr.length;
    let { min, max, color } = row;

    if (first) {
      output.push(color);
      first = false;
    }

    output.push(min);
    output.push(color);
    if (length - 1 == index) {
      output.push(max);
      output.push(color);
    }
    legendArray.push({
      min,
      max,
      color
    });

    cartoCssPart.push(
      ` #layer [ ${field.toLowerCase()} < ${max}] {   polygon-fill: ${color};} `
    );

    legendList.push(
      `<li><div class="bullet" style="border:1px solid #FFF; background:${color}"></div> ${min} - ${max}</li>`
    );
  });

  legendList.reverse();

  let legendString = legendList.join(" ");
  var legendOm = ` <div class='cartodb-legend custom'><div class="legend-title"> Climate Resilience Index <br />  ${
    field === "CRI" ? "" : name
  } </div><div class="legend-title"> </div><ul> ${legendString} </ul></div> `;

  cartoCssPart.reverse();

  cartoCssPart = cartoCssPart.join(" ");

  cartoOutput[field] = {
    name,
    field: field.toLowerCase(),
    cartoCssPart,
    legendOm
  };

  console.log(cartoOutput);

  return { style: output, legendArray };
};

if (genNewThematicStep || genNewThematicLinear) {
  //do nothing;
}

export default {
  data: () => ({
    colourBlindFriendly: false,
    currentPopup: null
  }),
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata,
      readyToUpdatePath: state => state.navigation.readyToUpdatePath
    }),
    thematicsIds() {
      return Object.keys(this.maplayerdata.thematics);
    },

    hasInfoIdsArray() {
      return Object.keys(this.maplayerdata.layers);
    },

    hasInfoIdsArrayAndCopy() {
      let values = Object.keys(this.maplayerdata.layers);
      let startingValues = Object.keys(this.maplayerdata.layers);

      values = [...values, ...startingValues.map(row => row + " growth")];
      values = [...values, ...startingValues.map(row => row + " openspace")];
      values = [...values, ...startingValues.map(row => row + "-copy")];

      //values.push("cri-mb-extra");
      return values;
    },
    thematicsValueArray() {
      return Object.values(this.maplayerdata.thematics);
    },
    layerValueArray() {
      return Object.values(this.maplayerdata.layers);
    }
  },
  methods: {
    updateThemeAndLayerFromIndex(themeIndex, layerIndex) {
      eventBus.$emit(
        //theme;
        "mapFilter_setThematics",
        this.thematicsValueArray[themeIndex].id
      );
      eventBus.$emit(
        //layer
        "mapFilter_setLayer",
        this.layerValueArray[layerIndex].id
      );
    },

    updateLayer(currentLayer) {
      eventBus.$emit("mapLegend_updateLayerId", currentLayer);

      this.clearAllPopups("G");

      let setLayerId = false;

      let layers = this.map.getStyle().layers;
      for (const layer of layers) {
        let { id: layerId } = layer;

        if (
          layerId &&
          this.hasInfoIdsArrayAndCopy &&
          this.hasInfoIdsArrayAndCopy.includes(layerId)
        ) {
          //do nothing;
          if (layerId.includes(currentLayer)) {
            this.map.setLayoutProperty(layerId, "visibility", "visible");
            //setLayerId = layerId;
          } else {
            this.map.setLayoutProperty(layerId, "visibility", "none");
          }
        }

        if (
          layerId &&
          this.hasInfoIdsArray &&
          this.hasInfoIdsArray.includes(layerId)
        ) {
          //do nothing;
          if (layerId.includes(currentLayer)) {
            setLayerId = layerId;
          }
        }
      }

      //forPath;
      if (
        setLayerId &&
        this.hasInfoIdsArray.includes(setLayerId) &&
        this.hasInfoIdsArray.indexOf(setLayerId) > -1
      ) {
        this.$store.dispatch(
          "navUpdate_layerIndex",
          this.hasInfoIdsArray.indexOf(setLayerId)
        );
      }
    },
    updateZoomDueToLayerLayer(value) {
      if (this.readyToUpdatePath) {
        let layer = this.maplayerdata.layers[value];
        if (layer?.target) {
          let zoom = this.map.getZoom();

          let { min, maxDesirable } = layer;

          if (zoom <= min || zoom >= maxDesirable) {
            this.map.flyTo({ zoom: layer?.target });
          }
        }

        this.map.set;
      }
    },
    updateThematics(value, colourBlindFriendly) {
      let thematicIndex = 0;
      try {
        thematicIndex = this.thematicsIds.indexOf(value);
      } catch (error) {
        //do nothing;
      }

      let thematicColorSet = this.maplayerdata.thematicSetAct[value];

      if (
        colourBlindFriendly &&
        colourBlindFriendly === true &&
        value === "CRI"
      ) {
        thematicColorSet = this.maplayerdata.thematicSetAct["CRI_colorblind"];
      }

      let thematicSetup = genNewThematicStep({
        field: value,
        thematicColorSet: thematicColorSet,
        name: this?.maplayerdata?.thematics[value]?.name
      });

      let layers = this.map.getStyle().layers;

      for (const layer of layers) {
        let { id: layerId } = layer;
        if (
          layerId &&
          this.hasInfoIdsArray &&
          this.hasInfoIdsArray.includes(layerId)
        ) {
          this.map.setPaintProperty(layerId, "fill-color", thematicSetup.style); // Change fill color to blue
          this.map.setPaintProperty(
            layerId,
            "fill-opacity",
            this.maplayerdata.mapOpacity
          ); // Change fill color to blue
        }
      }

      //forPath;
      if (thematicIndex > -1 && this.thematicsIds[thematicIndex]) {
        this.$store.dispatch("navUpdate_themeIndex", thematicIndex);
      }

      this.$store.dispatch("filters_update_thematics", {
        field: value,
        ...thematicSetup,
        fieldDetails: this.maplayerdata.thematics[value]
      });
    },

    addMapControlsFilters() {
      var emprtyTargetMenuOptions = {
        persistList: true,
        extraClass: "mapControlsWrapperControl  fakeEmptyMenuClass ",
        id: "mapControlsFakeDivTarget"
      };
      const emptyTargetControl = new EmptyTargetControl(
        emprtyTargetMenuOptions
      );

      this.map.addControl(emptyTargetControl, "top-right");

      setTimeout(() => {
        try {
          new MapControlsUi({
            propsData: {
              functions: {
                updateMobileFilterView: this.updateMobileFilterView,
                updateThematics: this.updateThematics,
                updateLayer: this.updateLayer,
                updateZoomDueToLayerLayer: this.updateZoomDueToLayerLayer
              },
              map: this.map,
              vuetifyCopy: this.$vuetify,
              appSettings: this.appSettings,
              maplayerdata: this.maplayerdata
            }
          }).$mount("#mapControlsFakeDivTarget");
        } catch (error) {
          //do nothink;
        }
      }, 10);
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
