<template>
  <div>
    <v-navigation-drawer
      v-if="false"
      touchless
      v-model="drawer"
      app
      right
      :width="
        $vuetify.breakpoint.smAndUp
          ? appSettings.drawWidth
          : appSettings.mobDrawWidth
      "
    >
    </v-navigation-drawer>

    <v-app-bar
      dense
      flat
      clipped-left
      app
      :color="'accent'"
      :parentDrawer="drawer"
      class="white--text"
      :style="{ 'border-bottom': `2px solid ${appSettings.logoTextColor}` }"
    >
      <div class="menuBarItem">
        <v-btn
          class="reloadButton iconOnlyMobileButton"
          @click="resetEverything()"
          title="Reset tool interface"
          color="primary"
        >
          <v-icon left>mdi-reload</v-icon>

          <span class="label">Reset</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <img
        @dblclick.stop="resetEverything()"
        class="criLogo py-1 mx-4"
        style="max-height:100%"
        :title="appTitle"
        :alt="appTitle"
        src="/assets/branding/Extended/CRI_Logo_Extended_Colour_1.png"
      />

      <v-toolbar-title class=" right">
        <div class=" headingText " v-if="false">
          <v-icon color="primary" large>mdi-shield-sun</v-icon>

          <span>
            {{ appTitle }}
          </span>
          <span
            class="xxfont-weight-light"
            v-if="!$vuetify.breakpoint.xsOnly"
          ></span>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="menuBarItem">
        <logos :contactFunction="loadContact" />
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map :drawer="drawer" />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer dense app :color="'accent'" class="white--text">
      <VLogos :contactFunction="loadContact" />
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer></v-spacer>

      <WelcomeModal v-if="false" class="mr-8" />

      <AboutDialog></AboutDialog>
      <GetInTouchDialog></GetInTouchDialog>
      <MailChimpSignUp></MailChimpSignUp>
      <ContactUsDialog></ContactUsDialog>

      <div class="mr-8"  v-if="!$vuetify.breakpoint.xsOnly">
        <a style="color:white" @click="loadAcknowlegement()">
          Acknowledgement<font v-if="!$vuetify.breakpoint.xsOnly">
            of Country
          </font>
        </a>
      </div>

      <div class="mr-8">
        <a style="color:white" @click="loadWelcome()"> <span>Welcome </span></a>
      </div>

      <div class="mr-8">
        <a style="color:white" @click="loadAbout()"> <span>About </span></a>
      </div>

      <div class="mr-8" v-if="!$vuetify.breakpoint.xsOnly">
        <a style="color:white" @click="loadShare()"> <span>Share </span></a>
      </div>

      <div class="mr-8">
        <a style="color:white" @click="loadGetTouchContact()">
          <span>Get in Touch </span></a
        >
      </div>

      <div class="mr-8">
        <a style="color:white" @click="loadMailChimpSignUpDialog()">
          <span>Newsletter Sign Up</span></a
        >
      </div>

      <YouTubeModal />
    </v-footer>
  </div>
</template>

<script>
import { eventBus } from "./main";

import { mapState } from "vuex";
//import RulesDialog from "./components/dialogs/RulesDialog";

import Map from "./components/map/Map.vue";
import AboutDialog from "./components/info/about/AboutDialog.vue";

import WelcomeModal from "./components/info/WelcomeModal.vue";
import GetInTouchDialog from "./components/info/GetInTouchDialog.vue";
import MailChimpSignUp from "./components/info/MailChimpSignUp.vue";
import ContactUsDialog from "./components/info/ContactUsDialog.vue";

import YouTubeModal from "./components/controls/YouTubeModal.vue";

import Logos from "./components/info/Logos.vue";
import VLogos from "./components/info/VLogos.vue";

export default {
  name: "MainAppLayout",
  components: {
    Map,
    Logos,
    VLogos,
    WelcomeModal,
    MailChimpSignUp,
    GetInTouchDialog,
    ContactUsDialog,
    AboutDialog,
    YouTubeModal
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      isDevEnviroment: state => state.navigation.isDevEnviroment
    })
  },
  data: () => ({
    appTitle: "Climate Resilience Index",
    drawer: false
  }),
  methods: {
    loadAcknowlegement() {
      eventBus.$emit("loadAcknowlegementDialog");
    },
    loadWelcome() {
      this.openIntro = false;
      eventBus.$emit("loadWelcomeDialog");
    },
    loadContact() {
      this.openIntro = false;
      eventBus.$emit("loadProjectTeamDialog");
    },
    loadGetTouchContact() {
      this.openIntro = false;
      eventBus.$emit("loadContactUsDialog");
    },
    loadMailChimpSignUpDialog() {
      this.openIntro = false;
      eventBus.$emit("loadMailChimpSignUpDialog");
    },

    loadShare() {
      this.openIntro = false;
      eventBus.$emit("openShareDialog");
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },

    resetEverything() {
      window.mvAnalyticEvent("resetButtonClicked");
      eventBus.$emit("nav_map_reset");
    }
  },
  created() {
    eventBus.$on("nav_layout_openMenu", () => {
      this.drawer = true;
    });

    eventBus.$on("nav_layout_closeMenu", () => {
      this.drawer = false;
      if (this.drawer) {
        this.drawer = false;
      }
    });
  },
  watch: {}
};
</script>

<style lang="scss">
/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}

.v-toolbar__content {
  padding-left: 8px;
  padding-right: 8px;
}

/* get rids of the banner at the bottom of the web page */
.skiptranslate,
.nullAdslkfjasd {
  display: none !important;
}
/*
.mapFrame,
.mapWrapper,
#mapContainer {
  height: calc(100vh - 100px + 16px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 110px);
    max-height: calc(var(--doc-height) - 110px);

    height: calc(100vh - 100px + 16px);
    max-height: calc(var(--doc-height) - 100px + 16px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
    max-height: calc(var(--doc-height) - 90px);

    height: calc(100vh - 90px + 8px);
    max-height: calc(var(--doc-height) - 90px + 8px);
  }

  footer {
    font-size: 0.7em;
    height: 34px;
  }
}

*/

.container > div {
  overflow: hidden !important;
}

.container > div > .mapWrapperWrapper {
  border: none;
  overflow: hidden !important;
}

.mapFrame,
.mapWrapper,
#mapContainer {
  height: 100vh;
  margin: 0;
  width: 100vw;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
}

#mapContainer {
  //height: 100%;
  width: calc(100vw + 20px);
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1263px) {
}
@media only screen and (max-width: 959px) {
  footer {
    font-size: 0.7em;
    height: 34px;
  }
}

@media only screen and (max-width: 959px) {
  footer {
    font-size: 0.7em;
    height: 34px;
  }
}

.v-card__title {
  word-break: initial;
}

@media only screen and (max-width: 960px) {
  .v-footer .mr-8 {
    margin-right: 8px !important;
  }
}

.fakeEmptyMenuClass {
  height: auto;
  min-height: 10px;
  min-width: 10px;
}

.isfullScreenMode .mapboxgl-ctrl mapbox-search-box {
  display: none !important;
}

.mapboxgl-ctrl mapbox-search-box svg {
  fill: white;
}

.mapboxgl-control-container
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-ctrl mapbox-search-box > div,
.mapboxgl-ctrl mapbox-search-box input,
.mapboxgl-ctrl mapbox-search-box input:focus,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-ctrl mapbox-search-box > div,
.mapboxgl-ctrl mapbox-search-box input,
.mapboxgl-ctrl mapbox-search-box input:focus {
  --colorText: rgba(255, 255, 255, 1);
  --colorPrimary: #4264fb;
  --colorSecondary: #bdbdbd;
  --colorBackground: #2e3a3d;
  --colorBackgroundHover: #1e2425;
  --colorBackgroundActive: #1e2122;
}

.mapboxgl-ctrl mapbox-search-box > div,
.mapboxgl-ctrl mapbox-search-box input,
.mapboxgl-ctrl mapbox-search-box input:focus {
  color: var(--colorText);
}

.mapboxgl-ctrl mapbox-search-box input::placeholder {
  color: rgba(255, 255, 255, 0.95);
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl-group
  button,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl-group
  button,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right-right
  .mapboxgl-ctrl-group
  button,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl-group
  .filterWrapper
  button.criThemeButtons,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl-group
  .filterWrapper
  button.criThemeButtons {
  width: 35px;
}

body div.v-application.my-app .mapboxgl-ctrl-group.fakeEmptyMenuClass {
  border-radius: 4px;
  xbackground-color: transparent;
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-group.fakeEmptyMenuClass
  button.mapElementButton {
  border-radius: 4px;
}

.mapboxgl-ctrl-top-center {
  top: 0;
  left: 50%;
  margin-top: -15px;
  margin-top: -17px;
  xmargin-top: 0;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-logo {
  margin-left: -200px;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-top-center,
.mapboxgl-ctrl-top-right-right,
.mapboxgl-ctrl-top-left-left {
  pointer-events: none;
  position: absolute;
  position: fixed;
  z-index: 2;
}

.mapboxgl-ctrl-top-center .mapboxgl-ctrl {
  float: left;
  margin: 10px 0 0 10px;
}

.isTabletOrMobile.mobileShowFilters .mapboxgl-ctrl-top-center {
  left: unset;
  right: unset;

  left: 0;
  top: 0;
  margin: 0;
  z-index: 10;
}

.isTabletOrMobile.mobileShowFilters .mapboxgl-ctrl-top-center .mapboxgl-ctrl {
  margin: 0;
}

.mapboxgl-ctrl-top-center .mapboxgl-ctrl.filterWrapperControl {
  xpadding: 0 10px;
}

.mapboxgl-ctrl-bottom-right {
  bottom: 0;
  right: 0;
}

.isTabletOrMobile .mapboxgl-ctrl-bottom-right {
  z-index: 10;
}

.mapboxgl-ctrl-top-left-left {
  //left: 47px;
  left: 0;
  //top: 0;
}

.mapboxgl-ctrl-top-right,
div > .mapboxgl-ctrl-bottom-right {
  right: 0;
}

.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-top-right-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-left-left {
  top: 48px;
}

.mapboxgl-ctrl-top-right-right {
  right: 410px;
  //top: 0;
}

.isSmallPcOrTabletOrMobile .mapboxgl-ctrl-top-left-left {
  //left: 0;
  //top: 0;
}

.isSmallPcOrTabletOrMobile .mapboxgl-ctrl-top-left {
  //left: 0;
  //top: 46px;
}

.isTablet .mapboxgl-ctrl-top-right-right {
  right: 210px;
  //top: 0;
}

.isMobile .mapboxgl-ctrl-top-right-right {
  right: 0;
  top: 95px; //46px+48px;
}

.mapboxgl-ctrl-top-left-left .mapboxgl-ctrl {
  float: left;
  margin: 10px 0 0 10px;
}

.mapboxgl-ctrl-top-right-right .mapboxgl-ctrl {
  float: right;
  margin: 10px 10px 0 0;
}

.isSmallPcOrTabletOrMobile.mobileShowSuburbList .mapboxgl-ctrl-top-left-left {
  left: unset;
  right: unset;

  margin: 0;
  z-index: 10;
}

.isSmallPcOrTabletOrMobile.mobileShowSuburbList
  .mapboxgl-ctrl-top-left-left
  .mapboxgl-ctrl {
  margin: 0;
}

.mapboxgl-ctrl .v-btn.mapElementButton {
  height: 36px;
  width: 35px;
  min-width: auto;
  padding: 0 0px 0 9px;
}

.isMobile .mapboxgl-ctrl-bottom-right {
  margin-bottom: 10px !important;
}

.mapboxgl-control-container
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group {
  //background: #2d3739;
  background: var(--colorBackground);

  border-radius: 4px;
}

.mapboxgl-control-container
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl.mapboxgl-ctrl-group
  button
  span,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group
  button
  span,
.mapboxgl-control-container
  .mapboxgl-ctrl-top-right-right
  .mapboxgl-ctrl.mapboxgl-ctrl-group
  button
  span {
  -webkit-filter: invert(80%) brightness(1.75);
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  float: left;
  margin-left: -30px;
  margin-top: -30px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact.mapboxgl-compact-show {
  float: right;
  margin-left: 0;
  margin-top: 0;
}

.mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
  bottom: 40px;
  right: 0;
  position: fixed;
}

@media only screen and (max-width: 959px) {
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
    bottom: 25px;
    right: 0;
    position: fixed;
  }
}

@media only screen and (max-width: 640px) {
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
    bottom: 25px;
    right: 0;
    position: fixed;
  }
}

.isfullScreenMode .mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
  bottom: 0;
  right: 0;
  position: fixed;
}
</style>
